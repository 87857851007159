<template>
  <div class="is-clickable is-unselectable has-background-light py-0 px-0">

    <div class="columns is-gapless is-multiline is-relative">
      <div class="button is-primary is-small px-1 m-0 "
        @click="()=>{ handleIsSelectedChange(!attachment.isSelected) }"
        :class="{'is-light': !attachment.isSelected}"
        style="position:absolute; top: 0; left: 0; z-index: 1">
        <i class="mdi mdi-check"
          :class="{'':attachment.isSelected,'has-text-grey-light':!attachment.isSelected}" />
      </div>

      <div class="column is-full is-clipped">
        <figure class="image is-3by4 is-fullwidth is-vcentered"
          @click="viewImage(true)">
          <img style="object-fit: cover"
            :src="thumbnailUrl">
        </figure>
        <quote-attachment-viewer-modal :active="modalOpen"
          :attachment="attachment"
          :quote-id="quoteId"
          :quote-attachment-id="quoteAttachmentId"
          :quote-attachment-category-id="quoteAttachmentCategoryId"
          @cancel="viewImage(false)"
          @updated-image="imageUpdated()" />
      </div>
      <div class="column is-full is-size-7 is-clipped columns is-multiline">
        <div class="column is-narrow p-0 m-0">
          <i class="mdi mdi-drag-vertical mdi-24px" />
        </div>
        <div class="columns is-multiline column is-clipped p-0 m-0">
          <div class="column is-full p-0 m-0"
            style="overflow:hidden;text-overflow: ellipsis; font-weight: bold; white-space: nowrap;"
            :tooltip="attachment.filename"
            :title="attachment.filename + '('+pagesCount+')' ">{{ attachment.filename }}({{ pagesCount }})</div>
          <div class="column is-full p-0 m-0">{{ fileDateTime }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuoteAttachmentViewerModal from './QuoteAttachmentViewerModal.vue'
import { DateTime } from 'luxon'

export default {
  name: 'QuoteAttachment',
  filters: {},
  components: { QuoteAttachmentViewerModal },
  props: {
    quoteId: {
      type: String
    },
    quoteAttachmentId: {
      type: String
    },
    quoteAttachmentCategoryId: {
      type: String
    },
    attachment: {
      type: Object
    },
    categories: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      modalOpen: false
    }
  },
  computed: {
    fileDateTime() {
      let dateTime = this.attachment.createdDate
      if (this.attachment.modifiedDate !== undefined) {
        dateTime = this.attachment.modifiedDate
      }
      return DateTime.fromISO(dateTime).toLocaleString()
    },
    pagesCountText() {
      var postfix = 'Page'
      if (this.pagesCount > 1) {
        postfix += 's'
      }
      return this.pagesCount + ' ' + postfix
    },
    pagesCount() {
      const value = this.attachment.quoteAttachmentDerivatives.reduce((max, row) => {
        if (max < row.pageIndex) {
          return row.pageIndex
        }
        return max
      }, 0)

      return value + 1
    },
    fileSize() {
      var size = this.attachment.fileSize
      var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
      return Math.round(size / Math.pow(1024, i)) + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i]
    },
    derivedSet() {
      return this.attachment.quoteAttachmentDerivatives.reduce((set, row) => {
        let pageIndex = row.pageIndex
        if (set[pageIndex] === undefined) {
          set[pageIndex] = {}
        }

        set[pageIndex][row.type] = row
        return set
      }, {})
    },
    thumbnail() {
      if (this.derivedSet[0]['modifiedPageImageThumbnails'] !== undefined) {
        return this.derivedSet[0]['modifiedPageImageThumbnails']
      }

      return this.derivedSet[0]['pageImageThumbnails']
    },
    thumbnailUrl() {
      return this.thumbnail.derivedExternalStorageURL
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleIsSelectedChange(value) {
      let isSelected = value
      //this.attachment.isSelected = isSelected
      //console.log('sending update:attachment', isSelected)
      this.$emit('update:attachment', { attachment: this.attachment, isSelected: isSelected })
    },
    imageUpdated() {
      this.$emit('image-uploaded')
    },
    async viewImage(state) {
      this.modalOpen = state
    }
  }
}
</script>
